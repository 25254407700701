<nav class="navbar navbar-expand-md navbar-dark gris ">

  <a class="navbar-brand" href="/">
    <img [hidden]="!imageLoader" src="assets/img/logo.png" alt="" style="width: 7.5rem;"/>
    <img [hidden]="imageLoader" [src]="logo" alt="" style="width: 7.5rem;" (load)="this.imageLoader = false;" />
    <mat-icon style="padding-top: -1%;">home</mat-icon>
  </a>


  <!-- <a class="navbar-brand" routerLink="/Inicio" style="width: 100%;">
    <img class="img-fluid" src="assets/img/urrea_white.svg" alt="" />
  </a> -->

  <button class="navbar-toggler" style="border: none" type="button" data-toggle="collapse"
    data-target="#navbarDropdownMenuLink" aria-controls="navbarDropdownMenuLink" aria-expanded="false"
    aria-label="Toggle navigation">
    <span style="color: white; font-size: 14px; margin-top: 5%;">{{ user.Name }}</span>
  </button>

  <div class="collapse navbar-collapse fondocollapse zonaDerecha d-sm-none" id="navbarNavAltMarkup">
    <div class="col">
      <ul class="navbar-nav nav-flex-icons listaIconos justify-content-end">
        <li class="nav-item dropdown mr-2 mt-auto mb-auto notificaciones">
          <a class="nav-link pb-0 pt-0" href="javascript:void(0);" id="notificacionsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="verNotificaciones()">
            <i class="fas fa-1x fa-bell" style="color: white;"></i><span *ngIf='show' class="badge badge-pill badge-warning" (click)="checkNotificacion()">{{ notificaciones.conteoNoLeidos }}</span>
          </a>
          <div id="text-notificaciones">Notificaciones</div>
          <div class="dropdown-menu" aria-labelledby="notificacionsDropdown">
            <a class="dropdown-item" href="javascript:void(0);" *ngFor="let item of notificaciones.notificaciones">
              <a style="color:currentcolor;text-decoration:none;display:block;" routerLink="/mis-reconocimientos" *ngIf="item.titulo=='Te reconocieron'" (click)="checkNotificacion()">
                <span style="font-size: 12px">{{item.titulo}}</span>
                <p>{{item.descripcion}}</p>
              </a>
              <div *ngIf="item.titulo!='Te reconocieron'" style="cursor:default;" (click)="checkNotificacion()">
                <span style="font-size: 12px">{{item.titulo}}</span>
                <p>{{item.descripcion}}</p>
              </div>
            </a>
            <a class="dropdown-item" href="javascript:void(0);" *ngIf="notificaciones.notificaciones.length == 0">No tienes notificaciones.</a>
          </div>
        </li>
        <li class="nav-item dropdown mr-2 mt-auto mb-auto">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span style="color: white;">{{ user.Name }}</span>
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a *ngIf="accesoAdmin" class="dropdown-item" routerLink="/admin">Administración</a>
            <a *ngIf="accesoAdmin" class="dropdown-item" routerLink="/admin-store">Administración Tienda</a>
            <a class="dropdown-item" href="javascript:void(0);" (click)="cerrarSesion()">Cerrar sesión</a>
          </div>
        </li>
        <li class="nav-item img-profile mt-auto mb-auto d-none d-md-block">
          <app-avatar-icon
              size="l"
              type="rounded-circle"
              bg_color="#19456E"
              text_color="white"
              [name]="user.Name"
              [image]="user.Avatar"
              loading="lazy"
          ></app-avatar-icon>
        </li>
      </ul>
    </div>
  </div>
</nav>


